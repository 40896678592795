import React, { useState } from "react"
import { injectIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import Footer from '../components/footer';
import Form from '../components/form';
import FormEn from '../components/form-en';
import SEO from '../components/seo';
import Thanks from '../components/thanks';

const Download = ({ intl }) => {
    const [type, setType] = useState('form');
    const { locale } = intl;

    function updateOnSuccess(){
        setType('thanks');
    }

    return (
        <Layout>
            <SEO title="Download" />

            <div className="main dark">
                {/* MARKETO */}
                {type !== 'thanks' ? (
                    locale !== 'en' ? <Form updateOnSuccess={updateOnSuccess} /> : <FormEn updateOnSuccess={updateOnSuccess} />
                )
                : <Thanks />
                }
                {/* FOOTER */}
                <Footer />
            </div>
        </Layout>

    )
}

export default injectIntl(Download)
