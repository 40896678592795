import React, { useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Link from './link';
import { CORE_FORMS, ECOMM_FORMS, FIELD_MAP } from '../common/constants';
import classnames from 'classnames';
import { getWindow } from 'ssr-window';
import { formStyle } from '../util/formStyle';
import { emailValidate } from '../util/formValidate';
import Loading from '../images/form/loading.gif';

const window = getWindow();

const Form = ({ intl, updateOnSuccess }) => {

    const { locale } = intl;
    const [type, setType] = useState('ecomm');
    const [marketoLoaded, setMarketoLoaded] = useState(false);
    const formId = ECOMM_FORMS[locale].id;
    const formSubscriber = ECOMM_FORMS[locale].subscriber;


    const coreId = CORE_FORMS[locale] ? CORE_FORMS[locale].id : false;
    const coreSubscriber = CORE_FORMS[locale] ? CORE_FORMS[locale].subscriber : false;

    // function mapValue(value)

    function formSync(direction = 'core') {
        const ecommForm = window.MktoForms2.getForm(formId);
        const coreForm = window.MktoForms2.getForm(coreId);

        const ecommData = ecommForm.getValues();
        const coreData = coreForm.getValues();


        FIELD_MAP.forEach((field) => {
            const { core, ecomm } = field;
            if (direction === 'core') {
                let data = {};
                if (core === "option3") {
                    data[core] = ecommData[ecomm].replace('Only', 'only');
                } else {
                    data[core] = ecommData[ecomm];
                }
                coreForm.setValues(data);
            } else {
                let data = {};
                if (core === "option3") {
                    data[ecomm] = coreData[core].replace('only', 'Only');
                } else {
                    data[ecomm] = coreData[core];
                }
                ecommForm.setValues(data);
            }
        });
    }

    useEffect(() => {
        const existingScript = document.getElementById('marketo-forms');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = '//app-lon03.marketo.com/js/forms2/js/forms2.min.js';
            script.id = 'marketo-forms';
            document.body.appendChild(script);

            script.onload = () => {
                setMarketoLoaded(true)
            };
        } else {
            setMarketoLoaded(true)
        }

    }, [locale]);

    useEffect(() => {

        if (marketoLoaded && formId && formSubscriber) {
            window.MktoForms2.loadForm(
                "//app-lon03.marketo.com",
                formSubscriber,
                formId,
                (form) => {
                    formStyle(formId);


                    form.onSuccess(() => {
                        updateOnSuccess();
                        return false;
                    });

                    form.onValidate(() => {
                        const { Email } = form.getValues();
                        if (!emailValidate(Email)) {
                            const emailElem = form.getFormElem().find("#Email");
                            form.submittable(false);

                            form.showErrorMessage(intl.formatMessage({
                                id: "blacklisted_email",

                            }), emailElem);
                        } else {
                            form.submittable(true);
                        }
                    });
                }
            );
        }
    }, [locale, marketoLoaded])

    return (
        <div className="block standard form">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h2>{intl.formatMessage({ id: "cta_download" })}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <p>{intl.formatMessage({ id: "download_page_copy" })}</p>
                    </div>
                </div>

                    <div className="row">
                        <div className="col">
                            <div className={classnames({
                                hidden: type !== 'ecomm'
                            })}>
                            { formId
                                ?
                                <form id={`mktoForm_${formId}`}><img src={Loading} alt="" className="loader" /></form>
                                :
                                <div>
                                    <p>{intl.formatMessage({ id: "download.formLoadError" })}</p>
                                    <Link to="/" title="" className="link-text"><span>{intl.formatMessage({ id: "thankyou_cta" })}</span></Link>
                                </div>
                            }
                            </div>
                            {locale === 'en'
                            ?
                                <div className={classnames({
                                    hidden: type === 'ecomm'
                                })}>
                                    <form id={`mktoForm_${coreId}`}><img src={Loading} alt="" className="loader" /></form>
                                </div>
                            : ''
                            }

                        </div>
                    </div>

            </div>
        </div>

    )
}

export default injectIntl(Form);
