import React, { useEffect, useState } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { CORE_FORMS, ECOMM_FORMS } from '../common/constants';
import { formStyle } from '../util/formStyle';
import { emailValidate } from '../util/formValidate';
import Loading from '../images/form/loading.gif';

const FormEn = ({ intl, updateOnSuccess }) => {

    const { locale } = intl;

    const [type, setType] = useState('ecomm');
    const [marketoLoaded, setMarketoLoaded] = useState(false);

    const formId = type === 'ecomm' ? ECOMM_FORMS[locale].id : CORE_FORMS[locale].id;
    const formSubscriber = type === 'ecomm' ? ECOMM_FORMS[locale].subscriber : CORE_FORMS[locale].subscriber;
    const formDomain = type === 'ecomm' ? ECOMM_FORMS[locale].domain : CORE_FORMS[locale].domain;


    const [formData, setFormData ] = useState({
        revenue: '',
        company: '',
        vertical: '',
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        title: '',
        business_type: '',
        payment_method: '',
        region: '',
        opt_in: ''
    });

    useEffect(() => {
        const existingScript = document.getElementById('marketo-forms');
        setMarketoLoaded(false);

        if (existingScript) {
            console.log('exists');
            existingScript.parentNode.removeChild(existingScript);
            window.MktoForms2 = null;
        }




        if (!window.MktoForms2) {
                const script = document.createElement('script');
                script.src = `//${formDomain}/js/forms2/js/forms2.min.js`;
                script.id = 'marketo-forms';
                document.body.appendChild(script);

                script.onload = () => {
                    console.log('script loaded');
                    setMarketoLoaded(true)
                    // setTest('Phil');
                };
            }


    }, [type]);

    useEffect(() => {

        const { region, payment_method } = formData;
        if (region.toLowerCase() === 'north america' && payment_method && payment_method.toLowerCase() !== 'online only') {
            setType('core');
        } else {
            setType('ecomm');
        }

    }, [formData])


    useEffect(() => {
        console.log(
            marketoLoaded,
            formId,
            formSubscriber,
            `//${formDomain}`
        );
        if (marketoLoaded && formId && formSubscriber) {
            console.log(window.MktoForms2);
            window.MktoForms2.loadForm(
                `//${formDomain}`,
                formSubscriber,
                formId,
                (form) => {
                    formStyle(formId);
                    console.log(formData);

                    populateForm(form);

                    Object.keys(form.getValues()).forEach((field) => {
                        document.querySelector(`#mktoForm_${formId} [name="${field}"]`).addEventListener('change', (e) => {
                            updateFormState(form.getValues());
                        });
                    });

                    form.onValidate(() => {
                        const { Email } = form.getValues();
                        if (!emailValidate(Email)) {
                            const emailElem = form.getFormElem().find("#Email");
                            form.submittable(false);
                            form.showErrorMessage(
                                intl.formatMessage({
                                    id: "blacklisted_email",
                                    defaultMessage: "Please use a valid business email address.",
                                 }), emailElem);
                        } else {
                            form.submittable(true);
                        }
                    });

                    form.onSuccess(() => {
                        updateOnSuccess();
                        return false;
                    });
                }
            );
        }
    }, [marketoLoaded])

    function transformValue(value) {

        console.log(value);

        if (type === 'ecomm') {
            if (value === 'Online only') {
                return 'Online Only';
            }
            if (value === 'In-store only') {
                return 'In-store Only';
            }
        } else {
            if (value === 'Online Only') {
                return 'Online only';
            }

            if (value === 'In-store Only') {
                console.log('In-store only');
                return 'In-store only';
            }
        }

        return value;
    }

    function populateForm(form) {

        console.log(formData);

        const {
            revenue,
            company,
            vertical,
            email,
            firstname,
            lastname,
            phone,
            title,
            business_type,
            payment_method,
            region,
            opt_in,
        } = formData;

        let formValues = {};

        switch (type) {
            case 'ecomm':
                formValues = Object.assign({}, {
                    AnnualRevenue: revenue,
                    Company: company,
                    Core_Vertical__c: vertical,
                    Email: email,
                    FirstName: firstname,
                    LastName: lastname,
                    Phone: phone,
                    Title: title,
                    Type_Of_Business__c: business_type,
                    howwouldyouliketoacceptpayments: transformValue(payment_method),
                    region: region,
                    singleOptIn: opt_in,
                })
                break;
            case 'core':
                formValues = Object.assign({}, {
                    Company: company,
                    Industry: vertical,
                    Email: email,
                    FirstName: firstname,
                    LastName: lastname,
                    Phone: phone,
                    option1: business_type,
                    option3: transformValue(payment_method),
                    Region__c: region,
                });
                break;
        }

        form.setValues(formValues);
    }

    function updateFormState(data) {
        let newFormData = formData;

        switch (type) {
            case 'ecomm':
                    const {
                        AnnualRevenue: ecommRevenue,
                        Company: ecommCompany,
                        Core_Vertical__c: ecommCore_Vertical__c,
                        Email: ecommEmail,
                        FirstName: ecommFirstName,
                        LastName: ecommLastName,
                        Phone: ecommPhone,
                        Title: ecommTitle,
                        Type_Of_Business__c: ecommType_Of_Business__c,
                        howwouldyouliketoacceptpayments: ecommHowwouldyouliketoacceptpayments,
                        region: ecommRegion,
                        singleOptIn: ecommSingleOptIn,
                    } = data;

                    newFormData = Object.assign({}, newFormData, {
                        revenue: ecommRevenue,
                        company: ecommCompany,
                        vertical: ecommCore_Vertical__c,
                        email: ecommEmail,
                        firstname: ecommFirstName,
                        lastname: ecommLastName,
                        phone: ecommPhone,
                        title: ecommTitle,
                        business_type: ecommType_Of_Business__c,
                        payment_method: ecommHowwouldyouliketoacceptpayments,
                        region: ecommRegion,
                        opt_in: ecommSingleOptIn
                    });
                break;
            case 'core':
                const {
                    Company: coreCompany,
                    Email: coreEmail,
                    FirstName: coreFirstName,
                    Industry: coreIndustry,
                    LastName: coreLastName,
                    Phone: corePhone,
                    Region__c: coreRegion__c,
                    option1: coreOption1,
                    option3: coreOption3,
                } = data;

                newFormData = Object.assign({}, newFormData, {
                    company: coreCompany,
                    vertical: coreIndustry,
                    email: coreEmail,
                    firstname: coreFirstName,
                    lastname: coreLastName,
                    phone: corePhone,
                    business_type: coreOption1,
                    payment_method: coreOption3,
                    region: coreRegion__c,
                });

                break;
        }
        setFormData(newFormData);
    }

    return (
        <div className="block standard form">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h2>{intl.formatMessage({ id: "cta_download" })}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7">
                        <p>{intl.formatMessage({ id: "download_page_copy" })}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {marketoLoaded ?
                            <form id={`mktoForm_${formId}`}><img src={Loading} alt="" className="loader" /></form>

                        : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(FormEn);
